import { connectNetwork } from 'lib/NetworkProvider'
import moment from 'moment-timezone'
import 'moment/locale/it'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Form, Icon, Input, Step, Table } from 'semantic-ui-react'
import { Articles } from '../containers'
import { baseViewDispatch, initializePage } from '../lib/util'
import View from '../lib/View'
import Material from './Material'
import Mold from './Mold'
import MachinePicker from 'job/MachinePicker'
function MaterialCheck(props) {
    const { network, toolbarResult, filtersResult, sortsResult, isEmployee } = props
    const [scannedMaterials, setScannedMaterials] = useState([])
    const [scannedCode, setScannedCode] = useState('')
    const [selectedArticle, setSelectedArticle] = useState(null)
    const [step, setStep] = useState(0)

    useEffect(() => {
        initializePage({ props: { toolbarResult, filtersResult, sortsResult } }, [], [], [])
    }, [])

    useEffect(() => {
        setImmediate(() => {
            const scannedCodeEl = document.getElementById('form-input-control-article-code')
            if (scannedCodeEl) {
                scannedCodeEl.focus()
                scannedCodeEl.onblur = (event) => {
                    setTimeout(() => {
                        var blurEl = document.getElementById('form-input-control-article-code')
                        if (blurEl) {
                            blurEl.focus()
                        }
                    }, 10)
                }
            }
        })
    }, [step])

    if (step === 1) {
        if (selectedArticle && selectedArticle.materials) {
            for (const material of selectedArticle.materials) {
                if (material.materialCode === scannedCode) {
                    setScannedMaterials([...scannedMaterials, material.moldCode])
                }
            }
            if (scannedMaterials.length === selectedArticle.materials.length) {
                setStep(2)
            }
        } else {
            setStep(2)
        }
    }

    return (
        <>
            <Form>
                <Form.Group widths="equal">
                    <Form.Field
                        id="form-input-control-article-code"
                        control={Input}
                        label={<span className="label">Scansione</span>}
                        name="scannedCode"
                        value={scannedCode}
                        onChange={(e) => {
                            setScannedCode(e.target.value)
                        }}
                        placeholder="SCANSIONA QUI"
                        style={{
                            fontSize: 64,
                        }}
                    />
                </Form.Group>
            </Form>
            <Step.Group style={{ width: '100%' }}>
                <Step active={step === 0}>
                    <Icon name="file alternate outline" />
                    <Step.Content>
                        <Step.Title>Articolo</Step.Title>
                        <Step.Description>Scansiona il codice commessa e seleziona l{"'"}articolo da produrre</Step.Description>
                    </Step.Content>
                </Step>

                <Step active={step === 1}>
                    <Icon name="cube" />
                    <Step.Content>
                        <Step.Title>Materiale</Step.Title>
                        <Step.Description>Scansiona il materiale corretto</Step.Description>
                    </Step.Content>
                </Step>
                <Step active={step === 2}>
                    <Icon name="check" />
                    <Step.Content>
                        <Step.Title>Fine</Step.Title>
                        <Step.Description>Procedura completa</Step.Description>
                    </Step.Content>
                </Step>
            </Step.Group>
            <Table celled>
                {step === 0 && (
                    <>
                        <Articles
                            isEmployee={isEmployee}
                            noActions
                            selectable
                            onChange={(article) => {
                                // console.log('Selected article', article)
                                setSelectedArticle(article)
                                setScannedCode('')
                                setStep(1)
                            }}
                            articleCodeFilter={scannedCode}
                            sub
                        />
                    </>
                )}

                {step === 1 && (
                    <>
                        <Material type="header" hideActions hideBarcode />
                        <Table.Body>
                            {selectedArticle && selectedArticle.materials
                                ? selectedArticle.materials.map((value, index) => {
                                      return (
                                          <Material
                                              key={value.materialId}
                                              type="table"
                                              material={value}
                                              hideActions
                                              hideBarcode
                                              isEmployee={isEmployee}
                                              scannedMaterials={scannedMaterials}
                                          />
                                      )
                                  })
                                : null}
                        </Table.Body>
                    </>
                )}
            </Table>
            <View fullw style={{ position: 'absolute', bottom: 16, right: 16, zIndex: 10, justifyContent: 'flex-end' }}>
                {step > 0 && (
                    <Button
                        onClick={() => {
                            setStep(step - 1)
                            setScannedMaterials([])
                        }}
                    >
                        Indietro
                    </Button>
                )}
                {step === 2 && (
                    <MachinePicker
                        onMachineChosen={async (machine) => {
                            this.setState({ inProduction: true })

                            if (machine.machineHadesRef && machine.machineEndpoint) {
                                if (machine.nodeMold) {
                                    await network.createCommand({
                                        node: machine.nodeMold,
                                        action: 1,
                                        value: `${this.state.moldCode}`,
                                        endpoint: machine.machineEndpoint,
                                    })

                                    // await network.createCommand({
                                    //     node: `${this.state.moldCode}`,
                                    //     action: 2,
                                    //     value: `AVVIO_PRODUZIONE_GESTIONALE_${this.state.moldCode}`,
                                    //     endpoint: 'ftp_ripi',
                                    // })
                                }

                                if (machine.nodeMoldId) {
                                    await network.createCommand({
                                        node: machine.nodeMoldId,
                                        action: 1,
                                        value: `${this.state.moldCode}`,
                                        endpoint: machine.machineEndpoint,
                                    })
                                }

                                // if (machine.nodeCavities) {
                                //     await network.createCommand({
                                //         node: machine.nodeQuantity,
                                //         action: 1,
                                //         value: `${this.state.molds[0].piecesPerCycle ? this.state.molds[0].piecesPerCycle : 1}`,
                                //         endpoint: machine.machineEndpoint,
                                //     })
                                // }

                                if (machine.nodeLot) {
                                    await network.createCommand({
                                        node: machine.nodeLot,
                                        action: 1,
                                        // value: `${result.lotId}`,
                                        value: `${this.state.productionId}`,
                                        endpoint: machine.machineEndpoint,
                                        datatype: 'UInt32',
                                    })
                                }
                            }
                            setStep(0)
                            setScannedMaterials([])
                        }}
                    />
                )}
            </View>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    let { getMachineList, deleteMold, mergeMold } = state

    return {
        getMachineList,
        deleteMold,
        mergeMold,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(MaterialCheck)))
