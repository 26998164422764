import { Box, VStack } from '@chakra-ui/layout'
import JsBarcode from 'jsbarcode'
import { connectNetwork } from 'lib/NetworkProvider'
import { routes, route_from } from 'lib/routes'
import View from 'lib/View'
import moment from 'momentconfig'
import React, { Component } from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import ReactToPrint from 'react-to-print'
import { Button, Icon, Input, Table } from 'semantic-ui-react'
import { Confirmation, ImageDefault } from '../components'
import SummarySheet from '../job/SummarySheet'
import { floatTimeToString, timeString } from '../lib/util'

const { app, articles, articleEdit, articleClone } = routes

class Article extends Component {
    constructor(props) {
        super(props)

        let { onCostChange, article } = props

        this.state = {
            deleting: false,
            ...article,
            articleMinutes: article ? parseFloat(article.articleMinutes) : 0,
        }

        if (onCostChange && article) {
            onCostChange(this.totalCost(), article.articleId)
        }
    }

    totalCost() {
        let { articleHourlyRate, articleHours, articleMinutes } = this.state
        let total = (articleHours + articleMinutes / 60) * parseFloat(articleHourlyRate)
        if (isNaN(total)) {
            total = 0
        }
        return total
    }

    componentDidMount() {
        let { article, articleId, articleCode, network } = this.props

        if ((!article || !article.articleId) && articleId) {
            network.getStdArticle(articleId)
        }

        if ((!article || !article.articleId) && articleCode) {
            network.getStdArticle(undefined, articleCode)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { article, articleCode, network } = this.props
        if ((!article || !article.articleId) && (this.props.articleId !== prevProps.articleId || this.props.articleCode !== prevProps.articleCode)) {
            if ((!article || !article.articleId) && this.props.articleId) {
                network.getStdArticle(articleId)
            }

            if ((!article || !article.articleId) && articleCode) {
                network.getStdArticle(undefined, articleCode)
            }
        }

        let { onCostChange, getStdArticle, login } = this.props
        let { articleId, articleHourlyRate } = this.state
        if (articleHourlyRate !== prevState.articleHourlyRate) {
            if (onCostChange) {
                onCostChange(this.totalCost(), articleId)
            }
        }

        if (getStdArticle && getStdArticle.fetching !== prevProps.getStdArticle.fetching && !getStdArticle.fetching) {
            if (getStdArticle.status.success) {
                let article = getStdArticle.data.data

                this.setState({ ...article })
                /*
				if (login && login.data.user && login.data.user.companies) {
					let company = login.data.user.companies[organization.data[0]]
					// console.log('Calling getFromIdFiltering', company, jobId)
					network.getFromIdFiltering(company, jobId, [
						{
							key: 'timestamp',
							symbol: '>',
							value: moment().subtract(4, 'hours').format('YYYY-MM-DD HH:mm:ss')
						}
					])
				}*/
                if (this.props.onLoad) {
                    this.props.onLoad(article)
                }
                return
            } else {
                // console.log('calling No article found with this id')
                if (this.props.onLoad) {
                    this.props.onLoad(false)
                }
            }
        }
    }

    renderBalanceFooter() {
        let { hourtotal, total } = this.props

        let averageHourlyCost = Math.round((total / hourtotal) * 100) / 100
        if (isNaN(averageHourlyCost)) {
            averageHourlyCost = '--'
        }

        return (
            <Table.Row>
                <Table.Cell width="5">Prezzo orario medio: {averageHourlyCost}</Table.Cell>
                <Table.Cell style={{ textAlign: 'right' }} width="4">
                    Totale Ore:
                </Table.Cell>
                <Table.Cell width="4">{floatTimeToString(hourtotal)}</Table.Cell>
                <Table.Cell width="1">Totale:</Table.Cell>
                <Table.Cell width="2">{total}€</Table.Cell>
            </Table.Row>
        )
    }

    renderBalanceHeader() {
        return (
            <Table.Header fullWidth>
                <Table.Row>
                    <Table.HeaderCell width="5">Nome Macchina</Table.HeaderCell>
                    <Table.HeaderCell width="4">Prezzo €/h</Table.HeaderCell>
                    <Table.HeaderCell width="4">Ore di lavoro</Table.HeaderCell>
                    <Table.HeaderCell width="1" />
                    <Table.HeaderCell width="2">Totale</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        )
    }

    renderForBalance() {
        let { articleId, articleHourlyRate, articleHours, articleMinutes } = this.state
        return (
            <Table.Row>
                <Table.Cell>{articleId}</Table.Cell>
                <Table.Cell>
                    <span className="no-print">
                        <Input
                            type="number"
                            step="0.01"
                            value={articleHourlyRate}
                            onChange={(e, data) => {
                                let value = data.value
                                if (value < 0) value = 0
                                this.setState({
                                    articleHourlyRate: value,
                                })
                            }}
                        />
                        €/h
                    </span>
                    <span className="only-print">{articleHourlyRate}€/h</span>
                </Table.Cell>
                <Table.Cell>{timeString(articleHours, articleMinutes)}</Table.Cell>
                <Table.Cell>=</Table.Cell>
                <Table.Cell>{this.totalCost()}€</Table.Cell>
            </Table.Row>
        )
    }

    renderTableFooter() {
        let { isBalance } = this.props
        if (isBalance) return this.renderBalanceFooter()
        else return null
    }

    renderTableHeader() {
        let { hideArticle, hideArticleHours, isBalance } = this.props
        if (isBalance) {
            return this.renderBalanceHeader()
        } else {
            return (
                <Table.Header fullWidth>
                    <Table.Row>
                        {!hideArticle && <Table.HeaderCell>Nome Macchina</Table.HeaderCell>}
                        {!hideArticleHours && <Table.HeaderCell>Ore di lavoro</Table.HeaderCell>}
                    </Table.Row>
                </Table.Header>
            )
        }
    }

    renderForTable() {
        let { hideArticle, hideArticleHours, isBalance } = this.props
        let { articleId, articleHours, articleMinutes } = this.state

        if (isBalance) {
            return this.renderForBalance()
        } else {
            return (
                <Table.Row>
                    {!hideArticle && <Table.Cell>{articleId}</Table.Cell>}
                    {!hideArticleHours && <Table.Cell>{timeString(articleHours, articleMinutes)}</Table.Cell>}
                </Table.Row>
            )
        }
    }

    openRemoveDialog() {
        this.setState({ deleting: true })
    }

    remove() {
        let { network, article } = this.props

        network.deleteStdArticle(article)

        this.setState({ deleting: false })
    }

    drawBarcode = () => {
        if (!this.props.noBarcode) {
            try {
                const text = this.state.articleCode
                const el = document.getElementById(`artbarcode${this.state.articleCode}`)
                if (el) {
                    JsBarcode(`#artbarcode${this.state.articleCode}`, text, {
                        format: 'CODE128',
                        // lineColor: '#0aa',
                        // width: 4,
                        // height: 40,
                        // displayValue: false,
                    })
                } else {
                    setImmediate(this.drawBarcode)
                }
            } catch (e) {
                console.error(e)
            }
        }
    }

    render() {
        let { login, type, article, onClick, selected, noActions, traversable } = this.props
        let {
            deleting,
            articleId: id,
            articleCode,
            articleName,
            articleDescription,
            articleImages,
            customerName,
            molds,
            packages,
            kits,
            materials,
            articleWeight,
            quantity,
            deliveryDate,
            creationDate,
        } = this.state

        let moldImage = null
        if (molds && molds.length > 0) {
            moldImage = molds[0].image
        }

        let extraprops = {
            fluid: type === 'full' ? true : false,
        }

        if (onClick) {
            extraprops.onClick = () => {
                onClick(article)
            }
        }
        
        let user = null
        if (login && login.authenticated) {
            user = login.data.user
        }

        if (!id && !articleCode) {
            return null
        }

        setImmediate(this.drawBarcode)

        switch (type) {
            case 'footer':
                return this.renderTableFooter()
            case 'header':
                return this.renderTableHeader()
            case 'table':
                return this.renderForTable()

            default:
                return (
                    <Box
                        ref={(el) => (this.componentRef = el)}
                        className={selected ? 'rowSelected' : ''}
                        style={styles.articleCard}
                        {...extraprops}
                        borderWidth="1px"
                        borderRadius="lg"
                        p="4"
                        overflow="hidden"
                        minH="300"
                    >
                        <Confirmation
                            message="Vuoi davvero eliminare questo articolo?"
                            showModal={deleting}
                            onConfirm={() => {
                                this.remove()
                            }}
                            onCancel={() => {
                                this.setState({ deleting: false })
                            }}
                        />
                        <Box textStyle="content" style={styles.collectionContent}>
                            <View fullw row>
                                {articleImages && articleImages.length > 0 && (
                                    <div style={styles.imageContainer}>
                                        <ImageDefault fromRoot src={articleImages[0].picturePath} ui={false} style={styles.articleImage} />
                                    </div>
                                )}
                                {!articleImages ||
                                    (articleImages.length === 0 && (
                                        <div style={styles.imageContainer}>
                                            <ImageDefault fromRoot src={moldImage} ui={false} style={styles.articleImage} />
                                        </div>
                                    ))}
                                <View fullw column>
                                    <Box textStyle="header">{articleName}</Box>
                                    <Box textStyle="description">{articleDescription}</Box>
                                    {creationDate && (
                                        <Box textStyle="description">Data di creazione: {moment(creationDate).format('DD/MM/YYYY HH:mm:ss')}</Box>
                                    )}
                                    {customerName && <Box textStyle="description">Cliente: {customerName}</Box>}
                                    {articleWeight && <Box textStyle="description">Peso: {articleWeight}g</Box>}
                                    <Box textStyle="meta">{articleCode}</Box>

                                    {this.state.molds && this.state.molds.length > 0 ? (
                                        <>
                                            {this.state.molds.map((value, index) => {
                                                return (
                                                    <Box
                                                        textStyle="description"
                                                        key={`article-mold-map-${articleCode}-${index}-${value.moldCode}-${value.jobCode}`}
                                                    >
                                                        {value.moldCode} {value.jobDescription ? value.jobDescription : value.moldDescription}
                                                    </Box>
                                                )
                                            })}
                                        </>
                                    ) : (
                                        ''
                                    )}

                                    {quantity && deliveryDate && (
                                        <Box textStyle="description">
                                            Quantità: {quantity} per il {moment(deliveryDate).format('DD/MM/YYYY')}
                                        </Box>
                                    )}
                                </View>

                                <View fullw column around>
                                    <Box
                                        key={`quantity-article-${articleCode}-${this.state.qty}`}
                                        alignItems="center"
                                        style={{ marginBottom: 0, alignSelf: 'center' }}
                                    >
                                        <Box fontSize="lg" textAlign={['center', 'center']}>
                                            Giacenza
                                        </Box>
                                        <Box fontSize="4xl" textStyle="buc" mb={0} mt={0} textAlign={['center', 'center']}>
                                            {this.state.qty}
                                        </Box>
                                    </Box>
                                </View>

                                {this.state.id_deposit && this.state.id_deposit !== undefined && this.state.id_deposit !== null && (
                                    <View fullw column around>
                                        <Box
                                            key={`quantity-article-${articleCode}-${this.state.qty}`}
                                            alignItems="center"
                                            style={{ marginBottom: 0, alignSelf: 'center' }}
                                        >
                                            <Box fontSize="lg" textAlign={['center', 'center']}>
                                                Deposito
                                            </Box>
                                            <Box fontSize="4xl" textStyle="buc" mb={0} mt={0} textAlign={['center', 'center']}>
                                                {this.state.name}
                                            </Box>
                                        </Box>
                                    </View>
                                )}

                                {!this.props.hideBarcode && (
                                    <View fullw column around>
                                        <Box textStyle="header" style={{ textAlign: 'center' }}>
                                            Codice a barre
                                        </Box>
                                        <div className="barcode-container" ref={(el) => (this.componentRef = el)}>
                                            <svg id={`artbarcode${articleCode}`}></svg>
                                        </div>
                                    </View>
                                )}

                                {this.state.masters && this.state.masters.length > 0 && (
                                    <View fullw column around>
                                        <Box textStyle="header" style={{ textAlign: 'center' }}>
                                            Master
                                        </Box>
                                        <View style={{ minHeight: 103 }} fullw row around>
                                            {this.state.masters &&
                                                this.state.masters.map((value, index) => (
                                                    <Box
                                                        key={`master-article-${index}-${value.masterId}`}
                                                        alignItems="center"
                                                        style={{ marginBottom: 0, alignSelf: 'center' }}
                                                    >
                                                        <Box fontSize="lg" textAlign={['center', 'center']}>
                                                            {value.masterName}
                                                        </Box>
                                                        <Box fontSize="4xl" textStyle="buc" mb={0} mt={0} textAlign={['center', 'center']}>
                                                            {value.masterPercent}%
                                                        </Box>
                                                    </Box>
                                                ))}
                                        </View>
                                    </View>
                                )}
                                {this.state.materials && this.state.materials.length > 0 && (
                                    <View fullw column around>
                                        <Box textStyle="header" style={{ textAlign: 'center' }}>
                                            Materiali
                                        </Box>
                                        <View style={{ minHeight: 103 }} fullw row around>
                                            {this.state.materials &&
                                                this.state.materials.map((value, index) => (
                                                    <Box
                                                        key={`material-article-${index}-${value.materialId}`}
                                                        alignItems="center"
                                                        style={{ marginBottom: 0, alignSelf: 'center' }}
                                                    >
                                                        <Box fontSize="lg" textAlign={['center', 'center']}>
                                                            {value.materialDescription}
                                                        </Box>
                                                        <Box fontSize="4xl" textStyle="buc" mb={0} mt={0} textAlign={['center', 'center']}>
                                                            {value.materialPercent}%
                                                        </Box>
                                                    </Box>
                                                ))}
                                        </View>
                                    </View>
                                )}
                            </View>
                        </Box>
                        {!noActions && (
                            <Box textStyle="content" extra className="no-print">
                                {!this.props.workOrderSummary && <SummarySheet type="article" objectToSerialize={this.state} />}
                                {this.props.workOrderSummary && <SummarySheet type="workorder" objectToSerialize={this.state} />}
                                {user && !!user.manage_article && (
                                    <Button
                                        color="blue"
                                        onClick={() => {
                                            this.props.history.push(route_from(app, articles, articleClone, `${id}`))
                                        }}
                                    >
                                        <Icon name="edit" />
                                        Clona
                                    </Button>
                                )}

                                {user && !!user.manage_article && (
                                    <Button
                                        color="blue"
                                        onClick={() => {
                                            this.props.history.push(route_from(app, articles, articleEdit, `${id}`))
                                        }}
                                    >
                                        <Icon name="edit" />
                                        Modifica
                                    </Button>
                                )}

                                {user && !!user.manage_article && (
                                    <Button
                                        color="red"
                                        onClick={() => {
                                            this.openRemoveDialog()
                                        }}
                                    >
                                        <Icon name="delete" />
                                        Elimina
                                    </Button>
                                )}
                                <ReactToPrint
                                    trigger={() => {
                                        return (
                                            <Button color="grey">
                                                <Icon name="print" />
                                                Stampa codice a barre
                                            </Button>
                                        )
                                    }}
                                    content={() => this.componentRef}
                                />
                            </Box>
                        )}
                    </Box>
                )
        }
    }
}

const styles = {
    header: {
        fontSize: '32pt',
        lineHeight: 'normal',
    },
    imageContainer: {
        minWidth: 200,
        minHeight: 150,
        marginRight: 28,
    },
    articleImage: {
        width: 200,
        height: 150,
        objectFit: 'contain',
    },
    articleContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    articleCard: {
        marginTop: 8,
        marginBottom: 8,
    },
}

const mapStateToProps = (state) => {
    let { login, getStdArticle } = state
    let rarticleId = null
    if (login && login.data && login.data.user) rarticleId = login.data.user.rarticleId

    return { role: rarticleId, getStdArticle, login }
}

export default withRouter(connect(mapStateToProps)(connectNetwork(Article)))
