import React, { Component } from 'react'

//router
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom'
import { route_from, routes } from 'lib/routes'
import { withRouter } from 'react-router'
// store
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import reducer from 'reducers/reducers'

import { connect } from 'react-redux'

import { Image } from 'semantic-ui-react'

// network provider
import Network from 'lib/NetworkProvider'
import { connectNetwork } from 'lib/NetworkProvider'
import { Main, Login } from 'containers'

import { CookiesProvider } from 'react-cookie'

import MetaTags from 'react-meta-tags'
import { Default } from 'lib/util'

import { ChakraProvider } from '@chakra-ui/react'

import { extendTheme, LightMode } from '@chakra-ui/react'
import Notifications from 'react-notification-system-redux'

import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'semantic-ui-css/semantic.min.css'

import 'semantic-ui-css/semantic.min.css'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import logovf from 'assets/logo-vf-alt.jpg'
import logomeletti from 'assets/logo-meletti.png'
import logotubi from 'assets/tubi.png'

const colors = {
    brand: {
        900: '#1a365d',
        800: '#153e75',
        700: '#2a69ac',
    },
}

export const theme = extendTheme({
    initialColorMode: 'light',
    useSystemColorMode: false,
    colors,
    semanticTokens: {
        colors: {
            error: 'red.500',
            success: 'green.500',
            primary: {
                default: 'red.500',
                _dark: 'red.400',
            },
            secondary: {
                default: 'red.800',
                _dark: 'red.700',
            },
        },
    },
    textStyles: {
        buc: {
            fontSize: ['48px', '72px'],
            fontWeight: 'bold',
            lineHeight: '110%',
            letterSpacing: '-2%',
            textTransform: 'uppercase',
            margin: 1,
        },
        content: {
            fontSize: 'sm',
            lineHeight: '100%',
            textTransform: 'capitalize',
            margin: 1,
        },
        description: {
            fontSize: 'md',
            lineHeight: '100%',
            textTransform: 'capitalize',
            colorScheme: 'gray',
            margin: 1,
        },
        meta: {
            fontSize: 'xs',
            lineHeight: '100%',
            textTransform: 'capitalize',
            colorScheme: 'brand',
            margin: 1,
        },
        header: {
            fontWeight: 'bold',
            fontSize: '3xl',
            lineHeight: '100%',
            textTransform: 'capitalize',
            margin: 1,
        },
    },
})

const { app, login, employee, insertion, terminal } = routes
const store = createStore(reducer, {}, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())

class PrivateRoute extends Component {
    constructor(props) {
        super(props)
        let {
            location: { pathname },
        } = this.props
        this.state = {
            pathToRestore: pathname,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { isEmployee, login, loginEmployee, history } = this.props
        let { pathToRestore } = this.state

        if (
            (login.authenticated !== prevProps.login.authenticated && login.authenticated && !isEmployee) ||
            (loginEmployee.authenticated !== prevProps.loginEmployee.authenticated && loginEmployee.authenticated && isEmployee)
        ) {
            if (pathToRestore !== '' && pathToRestore !== '/' && !pathToRestore.toLowerCase().startsWith('/Impiegato'.toLowerCase())) {
                history.replace(this.state.pathToRestore)
                this.setState({ pathToRestore: '' })
            } else {
                if (isEmployee) {
                    history.replace(route_from(employee, routes[process.env.REACT_APP_DEFAULT_WORKER_ROUTE]))
                } else {
                    history.replace(route_from(app, routes[process.env.REACT_APP_DEFAULT_ROUTE]))
                }
            }
        }
    }

    render() {
        let { component, isEmployee, render, login: loginStatus, loginEmployee: loginEmployeeStatus, notifications, ...rest } = this.props
        let Target = component

        return (
            <>
                <Route
                    {...rest}
                    render={(props) => {
                        if (!isEmployee) {
                            if (loginStatus.authenticated) {
                                return component ? <Target {...props} /> : render()
                            } else {
                                return <Redirect to={route_from(login)} />
                            }
                        } else {
                            if (loginEmployeeStatus.authenticated) {
                                return component ? <Target isEmployee {...props} /> : render()
                            } else {
                                return <Redirect to={route_from(employee)} />
                            }
                        }
                    }}
                />
                <Notifications notifications={notifications} />
            </>
        )
    }
}

const mapStateToProps = (state) => {
    let { login, loginEmployee, notifications } = state

    return {
        login,
        loginEmployee,
        notifications,
    }
}

const PrivateRouteConnected = withRouter(connect(mapStateToProps)(connectNetwork(PrivateRoute)))

class App extends Component {
    constructor(props) {
        super(props)

        this.state = {
            hasError: false,
        }
    }

    renderKrilaLogo = () => {
        return (
            <>
                <Image
                    style={{
                        zIndex: 100,
                        pointerEvents: 'none',
                        position: 'absolute',
                        height: 170,
                        top: -5,
                    }}
                    src={require('assets/black.png')}
                />
                <Image
                    style={{
                        zIndex: 101,
                        pointerEvents: 'none',
                        position: 'absolute',
                        width: 80,
                        top: 21,
                        left: 21,
                    }}
                    src={require('assets/krila-logo.png')}
                />
            </>
        )
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        // console.log('component did catch', error, info)
        this.setState({ hasError: true })
    }

    renderSyncodeLogo = () => {
        return (
            <>
                <Image
                    style={{
                        zIndex: 100,
                        pointerEvents: 'none',
                        position: 'absolute',
                        height: 170,
                        top: -5,
                    }}
                    src={require('assets/black.png')}
                />
                <Image
                    style={{
                        zIndex: 101,
                        pointerEvents: 'none',
                        position: 'absolute',
                        width: 80,
                        top: 5,
                        left: 5,
                    }}
                    src={require('assets/logo.png')}
                />
            </>
        )
    }

    renderMelettiLogo = () => {
        return (
            <>
                <Image
                    style={{
                        zIndex: 100,
                        pointerEvents: 'none',
                        position: 'absolute',
                        height: 170,
                        top: -5,
                        opacity: 0.9,
                    }}
                    src={require('assets/black.png')}
                />
                <Image
                    style={{
                        zIndex: 101,
                        pointerEvents: 'none',
                        position: 'absolute',
                        width: 120,
                        top: 25,
                        left: 5,
                    }}
                    src={logomeletti}
                />
            </>
        )
    }

    renderTubiLogo = () => {
        return (
            <>
                <Image
                    style={{
                        zIndex: 100,
                        pointerEvents: 'none',
                        position: 'absolute',
                        height: 170,
                        top: -5,
                        opacity: 0.5,
                    }}
                    src={require('assets/black.png')}
                />
                <Image
                    style={{
                        zIndex: 101,
                        pointerEvents: 'none',
                        position: 'absolute',
                        width: 100,
                        top: -5,
                        left: 5,
                    }}
                    src={logotubi}
                />
            </>
        )
    }

    renderIngeniobagLogo = () => {
        return (
            <>
                <Image
                    style={{
                        zIndex: 100,
                        pointerEvents: 'none',
                        position: 'absolute',
                        height: 170,
                        top: -5,
                        opacity: 0.9,
                    }}
                    src={require('assets/black.png')}
                />
                {/* <Image
                    style={{
                        zIndex: 101,
                        pointerEvents: 'none',
                        position: 'absolute',
                        width: 120,
                        top: 25,
                        left: 5,
                    }}
                    src={logomeletti}
                /> */}
            </>
        )
    }

    renderVfStampiLogo = () => {
        return (
            <>
                {/* <Image
                    style={{
                        zIndex: 101,
                        pointerEvents: 'none',
                        position: 'absolute',
                        width: 170,
                        top: 0,
                        left: 0,
                    }}
                    src={require('assets/logo-vf.png')}
                /> */}
                <Image
                    style={{
                        zIndex: 101,
                        pointerEvents: 'none',
                        position: 'absolute',
                        width: 110,
                        height: 90,
                        top: 0,
                        left: 0,
                        objectFit: 'cover',
                    }}
                    src={logovf}
                />
            </>
        )
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <h1>Qualcosa è andato storto; chiama Federico al 3334860925 per una soluzione</h1>
        }

        let extraRouterProps = {}
        if (this.props.history) {
            extraRouterProps.history = this.props.history
        }

        let hostname = window.location.hostname
        let location = window.location.pathname
        let adminHost = true
        let employeeHost = true

        let title = process.env.REACT_APP_ADMIN_TITLE

        if (hostname === process.env.REACT_APP_ADMIN_DOMAIN) {
            title = process.env.REACT_APP_ADMIN_TITLE
            employeeHost = false
        }

        if (hostname === process.env.REACT_APP_EMPLOYEE_DOMAIN) {
            title = process.env.REACT_APP_EMPLOYEE_TITLE
            adminHost = false
        }

        if (!employeeHost && location.startsWith(route_from(employee))) {
            window.location = route_from(app)
        }

        if (!adminHost && !location.startsWith(route_from(employee))) {
            window.location = route_from(employee)
        }

        // basename={'/gestionale'}
        return (
            <DndProvider backend={HTML5Backend}>
                <ChakraProvider theme={theme}>
                    {/* <LightMode> */}
                    <CookiesProvider>
                        <Provider store={store}>
                            <Network>
                                <React.Fragment>
                                    <Router {...extraRouterProps}>
                                        <MetaTags>
                                            <title>{title}</title>
                                        </MetaTags>

                                        {adminHost && <Route exact path={route_from(login)} component={Login} />}
                                        {employeeHost && <Route exact path={route_from(employee)} render={() => <Login isEmployee />} />}
                                        {adminHost && <PrivateRouteConnected path={route_from(app)} component={Main} />}
                                        {employeeHost && (
                                            <PrivateRouteConnected isEmployee path={route_from(employee)} render={() => <Main isEmployee />} />
                                        )}
                                    </Router>
                                    {/* TODO Resp */}
                                    {process.env.REACT_APP_BRANDING === 'krila' && <Default>{this.renderKrilaLogo()}</Default>}
                                    {process.env.REACT_APP_BRANDING === 'syncode' && <Default>{this.renderSyncodeLogo()}</Default>}
                                    {process.env.REACT_APP_BRANDING === 'vfstampi' && <Default>{this.renderVfStampiLogo()}</Default>}
                                    {process.env.REACT_APP_BRANDING === 'meletti' && <Default>{this.renderMelettiLogo()}</Default>}
                                    {process.env.REACT_APP_BRANDING === 'tubi' && <Default>{this.renderTubiLogo()}</Default>}
                                    {process.env.REACT_APP_BRANDING === 'ingeniobag' && <Default>{this.renderIngeniobagLogo()}</Default>}
                                </React.Fragment>
                            </Network>
                        </Provider>
                    </CookiesProvider>
                    {/* </LightMode> */}
                </ChakraProvider>
            </DndProvider>
        )
    }
}

export default App
