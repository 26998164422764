import { Box } from '@chakra-ui/layout'
import { connectNetwork } from 'lib/NetworkProvider'
import moment from 'moment-timezone'
import 'moment/locale/it'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Dimmer, Divider, Icon, Loader, Modal, Step, Table } from 'semantic-ui-react'
import { Job } from '../components'
import { Articles } from '../containers'
import { alertGenerator, baseViewDispatch } from '../lib/util'
import MoldDropdown from '../utility/MoldDropdown'
import Mold from './Mold'
import WorkOrder from './WorkOrder'
import WorkOrders from './WorkOrders'
function ProductionWizard({ success, error, network, isOpen, onClose, onConfirm, onCancel }) {
    const [step, setStep] = useState(0)
    const [mold, setMold] = useState(null)
    const [article, setArticle] = useState(null)
    const [moldObj, setMoldObj] = useState(null)
    const [toProduce, setToProduce] = useState({})
    const [loading, setLoading] = useState(false)
    const [selectedWorkOrderMap, setSelectedWorkOrderMap] = useState({})
    const [selectedWorkOrders, setSelectedWorkOrders] = useState({})

    useEffect(() => {
        if (mold) {
            network
                .getMold(mold)
                .then((result) => {
                    success(alertGenerator({ title: 'Recuperato commessa', message: '' }))
                    // console.log('result', result)
                    setMoldObj(result.data)
                })
                .catch((err) => {
                    error(alertGenerator({ title: 'Errore nel recupero commessa', message: 'Non esiste o il server non è raggiungibile' }))
                })
        }
    }, [mold])

    return (
        <Modal onClose={onClose} open={isOpen} style={{ width: '98%' }}>
            <Modal.Header>Creazione nuova produzione</Modal.Header>
            <Modal.Content image scrolling style={{ width: '100%', padding: 0 }}>
                <Box p={6} style={{ height: '100%', width: '100%', minHeight: 800, overflow: 'hidden' }}>
                    <Dimmer style={{ height: '100%', position: 'fixed' }} active={loading}>
                        <Loader />
                    </Dimmer>
                    <Step.Group style={{ width: '100%' }}>
                        <Step active={step === 0}>
                            <Icon name="file alternate outline" />
                            <Step.Content>
                                <Step.Title>Commessa</Step.Title>
                                <Step.Description>Seleziona la commessa</Step.Description>
                            </Step.Content>
                        </Step>

                        <Step active={step === 1}>
                            <Icon name="industry" />
                            <Step.Content>
                                <Step.Title>Seleziona cosa produrre</Step.Title>
                                <Step.Description>Ordini attivi o articoli</Step.Description>
                            </Step.Content>
                        </Step>

                        <Step active={step === 2}>
                            <Icon name="cube" />
                            <Step.Content>
                                <Step.Title>Riepilogo</Step.Title>
                                <Step.Description></Step.Description>
                            </Step.Content>
                        </Step>
                    </Step.Group>

                    {step === 0 && (
                        <>
                            <MoldDropdown
                                selected={mold}
                                onMoldSelected={(ev, data, selected, mold) => {
                                    setMold(mold)
                                    setToProduce({})
                                }}
                                onlySelection={true}
                                single
                            />
                            {mold && <Job key={mold} type="full" jobId={mold} noActions />}
                            {moldObj && (
                                <Table key={`${moldObj.moldCode}_table`}>
                                    <Mold type="header" noActions />
                                    <Table.Body>
                                        <Mold mold={moldObj} type="table" noActions />
                                    </Table.Body>
                                </Table>
                            )}
                        </>
                    )}

                    {step === 1 && (
                        <>
                            <Articles
                                sub
                                filters={{
                                    articleMold: {
                                        value: [{ moldCode: mold }],
                                        key: 'articleMold',
                                        operator: '=',
                                    },
                                }}
                                selectable
                                noActions
                                onChange={(article) => {
                                    // console.log('Article selected', article)
                                    setArticle(article)
                                }}
                            />

                            {article && (
                                <Box p={6} key={article.articleId}>
                                    <WorkOrders
                                        sub
                                        selectable
                                        filters={{
                                            articleCode: {
                                                value: article.articleCode,
                                                key: 'articleCode',
                                                operator: '=',
                                            },
                                        }}
                                        onChangeSelection={(workOrders, id, currentObject, checked) => {
                                            if (checked) {
                                                selectedWorkOrderMap[id] = currentObject
                                            } else {
                                                delete selectedWorkOrderMap[id]
                                            }
                                            setSelectedWorkOrders(selectedWorkOrders)
                                            setSelectedWorkOrderMap(selectedWorkOrderMap)
                                            // console.log('On change selection', checked, selectedWorkOrders, currentObject, selectedWorkOrderMap)
                                        }}
                                        defaultSelectedWorkOrders={selectedWorkOrders}
                                        noActions
                                    />
                                </Box>
                            )}
                        </>
                    )}

                    {step === 2 && (
                        <>
                            {/* {mold && <Job key={mold} type="full" jobId={mold} noActions />} */}
                            {moldObj && (
                                <Table key={`${moldObj.moldCode}_table`}>
                                    <Mold type="header" noActions />
                                    <Table.Body>
                                        <Mold mold={moldObj} type="table" noActions />
                                    </Table.Body>
                                </Table>
                            )}
                            <Table>
                                <WorkOrder type="header" noActions />
                                <Table.Body>
                                    {Object.keys(selectedWorkOrderMap).map((value, index) => {
                                        const order = selectedWorkOrderMap[value]
                                        return <WorkOrder key={`work_order_prod_${order.workOrderId}`} workOrder={order} type="table" noActions />
                                    })}
                                </Table.Body>
                            </Table>
                        </>
                    )}
                </Box>
            </Modal.Content>
            <Modal.Actions>
                {step === 0 && (
                    <Button color="grey" onClick={onCancel}>
                        Annulla <Icon name="cancel" />
                    </Button>
                )}
                {step > 0 && (
                    <Button
                        color="grey"
                        onClick={() => {
                            setStep(step - 1)
                            setArticle(null)
                        }}
                    >
                        Indietro <Icon name="arrow left" />
                    </Button>
                )}
                {step < 2 && (
                    <Button
                        color="green"
                        onClick={() => {
                            setStep(step + 1)
                        }}
                    >
                        Avanti <Icon name="arrow right" />
                    </Button>
                )}
                {step === 2 && (
                    <Button
                        primary
                        onClick={() => {
                            network.createProduction(
                                {
                                    machineId: null,
                                    moldId: mold,
                                },
                                selectedWorkOrders
                            )
                            onConfirm()
                        }}
                    >
                        Salva <Icon name="check" />
                    </Button>
                )}
                <Divider />
            </Modal.Actions>
        </Modal>
    )
}

const mapStateToProps = (state, ownProps) => {
    let {
        getMachineList,
        deleteProduction,
        mergeProduction,
        search: { data: searchText },
    } = state

    return {
        getMachineList,
        deleteProduction,
        mergeProduction,
        searchText,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(ProductionWizard)))
